/* eslint-disable react/no-danger */
/* eslint-disable react/no-unescaped-entities */
import React from 'preact/compat';
import Helmet from 'preact-helmet';

import { useAnimateFavicon } from '../../hooks/useAnimateFavicon';
import { useVibrate } from '../../hooks/useVibrate';
import style from './style.module.css';

const AgeConfirmation = ({ onConfirmAge }) => {
  useAnimateFavicon();
  useVibrate();

  return (
    <>
      <Helmet link={[{ rel: 'shortcut icon', href: '/assets/favicon.png' }]} title={`Confirm age`} />
      <script
        dangerouslySetInnerHTML={{
          __html: `
          (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
         m[i].l=1*new Date();
         for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
         k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
         (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
         ym(99111306, "init", {
              clickmap:true,
              trackLinks:true,
              accurateTrackBounce:true,
              webvisor:true
         });
        `,
        }}
      />
      <noscript>
        <div>
          <picture>
            <img src={`https://mc.yandex.ru/watch/99111306`} style={{ position: 'absolute', left: '-9999px' }} alt="" />
          </picture>
        </div>
      </noscript>

      <div className={style.root} style={{ backgroundImage: `url("/assets/images/blurredBg.webp")` }}>
        <div className={style.notificationContainer}>
          <img src="../../assets/icons/logo.svg" alt="Logo" height="70" width="208" />
          <div className={style.title}>This is an adult website</div>
          <div className={style.notificationText}>
            This website contains age-restricted materials including nudity and explicit depictions of sexual activity.
            By entering, you affirm that you are at least 18 years of age or the age of majority in the jurisdiction you
            are accessing the website from and you consent to viewing sexually explicit content.
          </div>
          <div className={style.closeButton} onClick={onConfirmAge}>
            ×
          </div>
          <div className={style.buttonsBlock}>
            <button className={style.confirmButton} onClick={onConfirmAge}>
              I'm 18 or older - open
            </button>
            <button className={style.rejectButton} onClick={onConfirmAge}>
              I'm under 18 - exit
            </button>
          </div>
          <div className={style.notificationText}>
            Our <span className={style.parentalControl}>Parental controls page</span> explains how you can easily block
            access to this site.
          </div>
          <div className={style.rights}>© 2024 PornStar.tube</div>
        </div>
      </div>
    </>
  );
};

export default AgeConfirmation;
