/* eslint-disable react/no-danger */
import React from 'preact/compat';
import Helmet from 'preact-helmet';

import { useAnimateFavicon } from '../../../hooks/useAnimateFavicon';
import { DETAILED_PAGE_CONFIGS } from '../../../shared/constants/detailedPagesConfigs';
import RecommendationCard from '../../RecomendationCard';
import VideoBlock from '../../VideoBlock';
import style from './style.module.css';

const DetailedPage = ({ category = 'anal' }) => {
  useAnimateFavicon();

  return (
    <div>
      <Helmet
        link={[{ rel: 'shortcut icon', href: '/assets/favicon.png' }]}
        title={`${category.toUpperCase()} Page`}
        meta={[{ name: 'description', content: `${category.toUpperCase()} Page` }]}
      />
      <>
        <script
          dangerouslySetInnerHTML={{
            __html: `
          (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
         m[i].l=1*new Date();
         for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
         k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
         (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
         ym(99114418, "init", {
              clickmap:true,
              trackLinks:true,
              accurateTrackBounce:true,
              webvisor:true
         });
        `,
          }}
        />
        <noscript>
          <div>
            <picture>
              <img
                src={`https://mc.yandex.ru/watch/99114418`}
                style={{ position: 'absolute', left: '-9999px' }}
                alt=""
              />
            </picture>
          </div>
        </noscript>
      </>
      <div class={style.root}>
        <h1 class={style.title}>{DETAILED_PAGE_CONFIGS[category].title}</h1>
        <div class={style.mainContentWrapper}>
          <div class={style.leftRecomendationsBlock}>
            {DETAILED_PAGE_CONFIGS[category].recommended.left.map((card, index) => {
              return <RecommendationCard key={card.imagePath + index} cardConfig={card} />;
            })}
          </div>
          <VideoBlock imagePath={DETAILED_PAGE_CONFIGS[category].videoPath} />
          <div class={style.rightRecomendationsBlock}>
            {DETAILED_PAGE_CONFIGS[category].recommended.right.map((card, index) => {
              return <RecommendationCard key={card.imagePath + index} cardConfig={card} />;
            })}
          </div>
        </div>
        <div class={style.title}>More videos</div>
        <div class={style.bottomRecomendationBlock}>
          {DETAILED_PAGE_CONFIGS[category].recommended.bottom.map((card, index) => {
            return <RecommendationCard key={card.imagePath + index} cardConfig={card} />;
          })}
        </div>
        <div class={style.commentsBlock}>
          <p class={style.commentsCount}>Comments (12)</p>
          <p class={style.commentsNotification}>You need to be authorised to read all comments</p>
        </div>
      </div>
    </div>
  );
};

export default DetailedPage;
