import { useEffect, useState } from 'preact/hooks';

export const useAnimateFavicon = () => {
  const [icons, setIcons] = useState(['/assets/favicon.png', '/assets/favicon.gif']);

  function setFavicon(url) {
    let links = document.querySelectorAll('link');

    links.forEach(item => {
      if (item.getAttribute('rel').indexOf('shortcut icon') >= 0) {
        item.setAttribute('href', url);
      }
    });
  }

  function startAnimation(icons, initial) {
    initial = initial === undefined ? 0 : initial;
    setFavicon(icons[initial]);
    let next = icons.length <= initial + 1 ? 0 : initial + 1;

    setTimeout(function () {
      startAnimation(icons, next);
    }, 300);
  }

  useEffect(() => {
    startAnimation(icons);
  }, [icons]);
};
