/* eslint-disable react/no-danger */
import React from 'preact/compat';
import Helmet from 'preact-helmet';

import { useAnimateFavicon } from '../../hooks/useAnimateFavicon';
import { useVibrate } from '../../hooks/useVibrate';
import style from './style.module.css';

const AlternativeAgeConfirmation = ({ onConfirmAge }) => {
  useAnimateFavicon();
  useVibrate();

  return (
    <>
      <Helmet link={[{ rel: 'shortcut icon', href: '/assets/favicon.png' }]} title={`Confirm age`} />
      <script
        dangerouslySetInnerHTML={{
          __html: `
          (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
         m[i].l=1*new Date();
         for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
         k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
         (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
         ym(99126822, "init", {
              clickmap:true,
              trackLinks:true,
              accurateTrackBounce:true,
              webvisor:true
         });
        `,
        }}
      />
      <noscript>
        <div>
          <picture>
            <img src={`https://mc.yandex.ru/watch/99126822`} style={{ position: 'absolute', left: '-9999px' }} alt="" />
          </picture>
        </div>
      </noscript>
      <div className={style.root}>
        <div className={style.blockWrapper}>
          <div className={style.notificationText}>
            To access content, please confirm your age. Are you already 18 years old?
          </div>
          <div className={style.closeButton} onClick={onConfirmAge}>
            ×
          </div>
          <div className={style.buttonsBlock}>
            <button className={style.confirmButton} onClick={onConfirmAge}>
              YES
            </button>
            <button className={style.rejectButton} onClick={onConfirmAge}>
              NO
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlternativeAgeConfirmation;
