import { useEffect } from 'preact/hooks';

export const useVibrate = () => {
  const vibrate = () => {
    const canVibrate = navigator.vibrate;

    if (canVibrate) navigator.vibrate(3000);
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', vibrate);
  }, []);
};
