/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-danger */
// eslint-disable-next-line import/no-unresolved
import React from 'preact/compat';
import Router, { route } from 'preact-router';

import './style';
import AgeConfirmation from './components/AgeConfirmation';
import AlternativeAgeConfirmation from './components/AlternativeAgeConfirmation';
import Footer from './components/Footer';
import Header from './components/Header';
import Navbar from './components/Navbar';
import DetailedPage from './components/pages/DetailedPage';

const App = () => {
  const onConfirmAge = () => {
    route('/latina');
  };

  return (
    <div id="app">
      <Header />
      <Navbar />
      <main>
        <Router>
          <AgeConfirmation path={'/'} onConfirmAge={onConfirmAge} />
          <AlternativeAgeConfirmation path={'/confirm'} onConfirmAge={onConfirmAge} />
          <DetailedPage path={'/latina'} />
        </Router>
      </main>
      <Footer />
    </div>
  );
};

export default App;
